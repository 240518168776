.line {
	margin: var(--line-margin-height) 0 0 0;

	&:first-child {
		margin-top: 0;
	}

	border-bottom: solid var(--color-separation) 1px;

	p {
		margin: 0 0 0 0;
		padding: 0 0 0 0;
	}

	.author {
		color: var(--research-publication-line-author-color);
	}

	.journal {
		color: var(--research-publication-line-journal-color);
		font-style: italic;
	}
}

:root {
	--research-publication-line-author-color: #ff6;
	--research-publication-line-journal-color: #6f6;
}

:global(.is-light) {
	--research-publication-line-author-color: #955c00;
	--research-publication-line-journal-color: #050;
}
