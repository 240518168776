// BEGIN Thèmes
:root {
	// Default theme is dark.
	--color-background: black;
	--color-separation: #005;
	--color-text: white;

	--href-text-color: #aaf;

	--menu-icon-closed-background-color: #111;
	--menu-icon-open-background-color: #555;

	--menu-background-color: black;
	--menu-border-color: #444;

	--menu-item-background-color: #111;
	--menu-item-color-hover: #1f1f1f;
}

:global(.is-light) {
	// Theme light.
	--color-background: white;
	--color-separation: #bbf;
	--color-text: black;

	--href-text-color: #11b;

	--menu-icon-closed-background-color: #eee;
	--menu-icon-open-background-color: #aaa;

	--menu-background-color: white;
	--menu-border-color: #bbb;

	--menu-item-background-color: #eee;
	--menu-item-color-hover: #e0e0e0;
}
// END Thèmes

:root {
	// Les marges haute et gauche du document entier.
	--document-margin-top: 20px;
	--document-margin-left: 8px;

	--column-space-between-menu-and-main: 20px;

	// BEGIN Menu
	--menu-width: 250px;
	--menu-icon-height: 32px;
	--menu-icon-width: 32px;

	// When on desktop.
	--menu-column-width: var(--menu-width);

	:global(.is-mobile) {
		--menu-column-width: var(--menu-icon-width);
	}

	--menu-border-radius: 10px;
	--menu-break-height: 20px;

	--menu-font-weight: 1.2em;

	// BEGIN Menu Items
	--menu-item-padding: 5px;
	--menu-item-padding-hover: 10px;
	// END Menu Items

	// BEGIN Menu Icone
	--menu-icon-border-width: 5px;
	// END Menu Icone
	// END Menu

	// BEGIN Titre
	--title-margin-left: 50px;
	--title-margin-bottom: 20px;

	--title-height: 2em;
	// END Titre

	// BEGIN Contenu principal
	// La largeur maximale que peut prendre le contenu principal.
	--main-width-max: 1000px;

	--line-margin-height: 20px;
	// END Contenu principal
}