.content {
	display: grid;

	// BEGIN When on desktop.
	grid-template-areas:
		"menu titre"
		"menu principal";

	grid-template-columns: var(--menu-column-width) minmax(auto, var(--main-width-max));
	column-gap: var(--column-space-between-menu-and-main);

	grid-template-rows: min-content 1fr;
	row-gap: var(--title-margin-bottom);
	// END When on desktop.

	:global(.is-mobile) & {
		grid-template-areas:
			"menu titre"
			"principal principal";

		grid-template-columns: var(--menu-column-width) minmax(auto, var(--main-width-max));
		column-gap: var(--column-space-between-menu-and-main);

		grid-template-rows: minmax(var(--menu-icon-height), min-content) 1fr;
		row-gap: var(--title-margin-bottom);
	}
}