@use "Constantes.module.scss";

html {
	background-color: var(--color-background);
	color: var(--color-text);

	* {
		background-color: var(--color-background);
		color: var(--color-text);
	}
}

body {
	font-family: "Liberation Sans";

	margin: var(--document-margin-top) 0 0 var(--document-margin-left);

	a {
		text-decoration: none;
		color: var(--href-text-color);
		font-weight: bold;
	}
}
