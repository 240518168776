.menu-item {
	background-color: var(--menu-item-background-color);
	font-size: inherit;

	border: none;

	cursor: pointer;

	text-align: left;

	margin: 0 0 0 0;
	padding: var(--menu-item-padding) 0 var(--menu-item-padding) var(--menu-item-padding);
	width: 100%;

	&:hover {
		background-color: var(--menu-item-color-hover);

		padding-left: var(--menu-item-padding-hover);
	}
}
