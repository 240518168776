.name {
	font-weight: bold;
	color: var(--project-name-color);

	margin: 0 0 10px 0;
}

.description {
	font-weight: normal;

	margin: 10px 0 10px 0;
}

:root {
	--project-name-color: #f3d;
}

:global(.is-light) {
	--project-name-color: #750b63;
}
