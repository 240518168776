.menu-container {
	grid-area: menu;
	position: sticky;
	display: flex;
	flex-flow: column nowrap;
	top: var(--document-margin-top);
	height: var(--menu-icon-height);
}

.menu {
	background-color: var(--menu-background-color);
	font-size: var(--menu-font-weight);

	width: var(--menu-width);

	:global(.is-mobile) & {
		border: solid var(--menu-border-color) 1px;
		border-radius: 0 var(--menu-border-radius) var(--menu-border-radius) var(--menu-border-radius);

		button {
			&:nth-child(1) {
				border-top-right-radius: var(--menu-border-radius);
			}

			&:nth-last-child(1) {
				border-radius: 0 0 var(--menu-border-radius) var(--menu-border-radius);
			}
		}

		&.invisible {
			display: none;
		}
	}
}
