.menu-button {
	height: var(--menu-icon-height);
	width: var(--menu-icon-width);

	padding: 0 0 0 0;
	margin: 2px 0 0 0;

	border: solid var(--menu-border-color) 1px;

	// When on desktop.
	display: none;

	:global(.is-mobile) & {
		display: grid;
	}

	svg {
		background-color: transparent;

		padding: 0 0 0 0;
		margin: 0 0 0 0;
	}

	&.menu-is-closed {
		background-color: var(--menu-icon-closed-background-color);
		border-radius: var(--menu-icon-border-width);
	}

	&.menu-is-opened {
		background-color: var(--menu-icon-open-background-color);
		border-radius: var(--menu-icon-border-width) var(--menu-icon-border-width) 0 0;
	}
}
